<template>
  <div>
    <b>{{ local_time }}</b>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["time"],
  data() {
    return {
      local_time: "00:00:00",
    };
  },
  mounted() {
    this.local_time = "00:00:00";
    this.calculateTime();
    // this.local_time = this.time;
  },
  methods: {
    calculateTime() {
      setInterval(() => {
        this.calculateTimeLeft();
      }, 1000);
    },
    calculateTimeLeft() {
      // spoc.displaySpocTime = false;
      //   console.log(this.time);
      // console.log("still calculating");
      if (this.time !== null) {
        let current_time = moment();
        let queue_expiry_time = moment.unix(this.time);
        let time_difference = this.secondsToHms(
          queue_expiry_time.diff(current_time, "seconds")
        );
        if (
          queue_expiry_time.diff(current_time, "seconds") === 0 ||
          queue_expiry_time.diff(current_time, "seconds") < 0
        ) {
          this.local_time = "00:00:00";
        } else {
          //   console.log(time_difference);
          this.local_time = time_difference;
        }
      } else {
        this.local_time = "00:00:00";
      }
    },
    secondsToHms(secs) {
      var minutes = Math.floor(secs / 60);
      secs = secs % 60;
      var hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(secs)}`;
    },
    pad(num) {
      return ("0" + num).slice(-2);
    },
  },
};
</script>

<style></style>
