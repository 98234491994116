<template>
  <div>
    <vx-card actionable class="cardx" style="min-height: 700px" title="History">
      <vs-row type="flex" style="margin-top: 2%">
        <vs-col
        v-if="!getTeam('GM')"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <vs-button
          v-if="!getTeam('GM')"
            class="rounded-r-none p-3 md:px-8 md:py-3"
            @click="cmTeamPermissions('CM')"
            :color="colorx"
            :style="cmteamselectbuttoncolor"
            size="small"
            type="border"
            >CM</vs-button
          >
          <vs-button
            v-if="!getTeam('GM')"
            class="rounded-l-none p-3 md:px-8 md:py-3"
            @click="exedTeamPermissions('ExEd')"
            :color="colorx"
            :style="exedteamselectbuttoncolor"
            size="small"
            type="border"
            >ExEd</vs-button
          >
        </vs-col>
        <vs-col
        v-if="!getTeam('GM')"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="4"
        >
          <v-select
           v-if="!getTeam('GM')"
            id="courseselect"
            class="w-full"
            placeholder="City"
            v-model="selected_city"
            :options="permissions_history_cities"
            label="text"
            style="height: 20px; z-index: 1000; bottom: 5px; position: relative"
          ></v-select>
        </vs-col>

        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <div id="datepicker" style="padding-top: 5px">
            <date-range-picker
              ref="picker"
              :opens="'right'"
              :locale-data="{ firstDay: 1, format: 'DD-MMM-YYYY' }"
              :minDate="null"
              :maxDate="null"
              :singleDatePicker="false"
              :timePicker="false"
              :timePicker24Hour="true"
              :showWeekNumbers="false"
              :showDropdowns="false"
              :autoApply="true"
              v-model="date_range"
              style="width: 240px"
            ></date-range-picker>
            <!-- <datepicker placeholder="Select Date" v-model="today_date"></datepicker> -->
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <vs-button color="dark" type="filled" @click="getQueueHistory()"
            >GO</vs-button
          >
        </vs-col>
      </vs-row>
      <vs-row type="flex" style="margin-top: 2%">
        <vs-table :data="queue_history" style="width: 100%">
          <template slot="thead">
            <vs-th>City</vs-th>
            <vs-th>SPOC Name</vs-th>
            <vs-th>Permission Given(Hours)</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody ref="tbody">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <div>{{ tr.city }}</div>
                </vs-td>
                <vs-td>
                  <div>{{ tr.first_name }} {{ tr.last_name }}</div>
                </vs-td>
                <vs-td>
                  <vs-button
                    @click="openPopup(tr,date_range)"
                    color="primary"
                    type="flat"
                    >{{ tr.total_minutes }}</vs-button
                  >
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
        <vs-popup
          class="holamundo"
          :title="`${popup_title}`"
          :active.sync="popupActivo"
        >
          <vs-table :data="popup_data">
            <template slot="thead">
              <vs-th> Date & Time </vs-th>
              <vs-th> Duration(hrs) </vs-th>
               <vs-th> Permission Given By </vs-th>
                <vs-th> Permission Revoked By </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].created_at">
                  {{ data[indextr].created_at }}
                </vs-td>

                <vs-td :data="data[indextr].total_minutes">
                  {{ data[indextr].total_minutes }}
                </vs-td>

                 <vs-td :data="data[indextr].permission_given_by">
                  {{ checkIfNull(data[indextr].permission_given_by) }}
                </vs-td>
                 <vs-td :data="data[indextr].permission_revoke_by">
                  {{ checkIfNull(data[indextr].permission_revoke_by) }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-popup>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import axios from "axios";
import constants from "../../../constants.json";
// import EventBus from "../components/eventbus";
import vSelect from "vue-select";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  data() {
    return {
      popupActivo: false,
      popup_title: "",
      popup_data: [],
      permissions_history_cities: [],
      queue_history: [],
      date_range: {
        startDate: new Date(),
        endDate: new Date(),
      },
      selected_city: "",
      open: false,
      colorx: "",
      currentTablePage: 1,
      tablelinks: 1,
      spoc_names: [],
      iiml_spocs: [],
      spocs: [],
      CitySpocs: [],
      // city:"",
      selectedTeam: "CM",
      cmteamselectbuttoncolor: [
        {
          background: "rgb(199, 42, 117)",
          color: "#ffffff",
          border: "1px solid rgb(199, 42, 117)",
        },
      ],
      exedteamselectbuttoncolor: [
        {
          background: "#ffffff",
          color: "rgb(199, 42, 117)",
          border: "1px solid rgb(199, 42, 117)",
        },
      ],
    };
  },
  components: {
    vSelect,
    DateRangePicker,
  },
  watch: {
    selected_city(value) {
      this.selected_city = value;
    },
  },
  created() {
    // var self = this;
    // setInterval(function() {
    // self.now = moment();
    // console.log(self.now);
    // }, 1000);
  },
  computed: {},
  mounted() {
    this.permissions_history_cities = [];
    console.log("date range", this.permissions_history_cities);
    if(this.getTeam("GM")){
      this.getGmReportingSpocs("CM");
    }
    if(!this.getTeam("GM")){
      this.getSpocs(this.selectedTeam);
    }
  },
  methods: {
  getGmReportingSpocs(team) {
      let url = `${constants.SERVER_API}getGmReportingSpocs?team=${team}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {   
          console.log("getGmReportingSpocs",response);
           this.CitySpocs = response.data.spocs;
          this.CitySpocs.forEach((spoc) => {
            spoc.time_left_formatted = "00:00:00";
            spoc.full_name_team = `${spoc.full_name} - ${spoc.sub_team}`
          });
          this.loggedin = response.data.logged_in_user_id;
          this.extractSpocByCity(response.data.spocs, team);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  openPopup(data,date) {
      console.log("popup data",data);
      this.popup_data = [];
      let start_date = moment(date.startDate).format("YYYY-MM-DD");
      let end_date = moment(date.endDate).format("YYYY-MM-DD");
      this.popup_title =
        data.first_name + data.last_name + "  " + start_date + " - " + end_date + "  " +`[${data.total_minutes}]`;
      
      let start_time = moment(date.startDate).startOf('day').format("YYYY-MM-DD hh:mm:ss");
      let end_time = moment(date.endDate).endOf('day').format("YYYY-MM-DD hh:mm:ss");

      this.popupActivo = true;

      let url = `${constants.SERVER_API}getDateWiseQueueHistory`;
        axios
        .get(url, {
          params:{
            user_id :  data.id,
            start_date : start_time,
            end_date : end_time,          },
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("popup data",response);
          this.popup_data = response.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    cmTeamPermissions(team) {
      this.selectedTeam = team;
      console.log(
        "cmTeamPermissions",
        this.selectedTeam,
        this.selected_city,
        this.date_range
      );
      this.date_range = moment(this.date_range).format("YYYY-MM-DD hh:mm:ss");
      this.exedteamselectbuttoncolor = [
        {
          background: "#ffffff",
          color: "rgb(199, 42, 117)",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
      this.cmteamselectbuttoncolor = [
        {
          background: "rgb(199, 42, 117)",
          color: "#ffffff",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
      this.permissions_history_cities = [];
      this.getSpocs(team);
      // this.getQueueHistory();
    },
    checkIfNull(name){
        if(name != null){
            return name
        }else {
          return "-"
        }
    },
    exedTeamPermissions(team) {
      this.selectedTeam = team;
      console.log("YYYY-MM-DD hh:mm:ss", this.date_range);
      console.log(
        "exedTeamPermissions",
        this.selectedTeam,
        this.selected_city,
        this.date_range
      );
      this.cmteamselectbuttoncolor = [
        {
          background: "#ffffff",
          color: "rgb(199, 42, 117)",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
      this.exedteamselectbuttoncolor = [
        {
          background: "rgb(199, 42, 117)",
          color: "#ffffff",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
      this.permissions_history_cities = [];
      this.getExed(team);
      //  this.getQueueHistory();
    },
    getQueueHistory() {
      console.log(this.date_range.startDate, this.date_range.endDate);
      let start_date = moment(this.date_range.startDate)
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      let end_date = moment(this.date_range.endDate)
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      // this.date_range.end_date = moment(this.date_range.end_date).format("YYYY-MM-DD hh:mm:ss");
      console.log(
        "cmTeamPermissions",
        this.selectedTeam,
        this.selected_city,
        start_date,
        end_date
      );
      let url = `${constants.SERVER_API}getQueueHistory?team=${this.selectedTeam}&city=${this.selected_city}&start_date=${start_date}&end_date=${end_date}&type=${this.selectedTeam}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getQueueHistory", response);
          this.queue_history = response.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSpocs(team) {
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.spoc_names = response.data.spocs;
          this.spoc_names.forEach((spoc) => {
            spoc.time_left_formatted = "00:00:00";
            spoc.full_name_team = `${spoc.full_name} - ${spoc.city}`;

            // spoc.displaySpocTime = false;
          });
          this.extractSpocByCity(response.data.spocs, team);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    extractSpocByCity(response) {
      var unique = response
        .map((name) => {
          return {
            count: 1,
            name: name.city,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      let city_options = ["Ahmedabad","Bangalore","Chennai","Delhi","Ernakulam","Hyderabad","Kolkata","Mumbai","Pune",]
      this.permissions_history_cities = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          if(city_options.includes(key)){
            this.permissions_history_cities.push(key);
          }
        }
      }
      this.permissions_history_cities = this.sortArrayAlphabetically(
        this.permissions_history_cities
      );
      // if (this.city == "") {
      this.selected_city = this.permissions_history_cities[0];
      console.log("fgfghytyt",this.selected_city );
    },
    getExed(team) {
      let url = `${constants.SERVER_API}getExed`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("hey", response);
          this.spoc_names = response.data.spocs;
          this.loggedin = response.data.logged_in_user_id;
          this.extractSpocBySubTeam(response.data.spocs, team);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    extractSpocBySubTeam(response) {
      var unique = response
        .map((name) => {
          return {
            count: 1,
            name: name.sub_team,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});

      this.permissions_history_cities = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          if (key != "IIML-FA" && key != "IIML-SF" && key != "IIML-FT" && key != "null") {
            this.permissions_history_cities.push(key);
          }
        }
      }
      console.log("extractSpocBySubTeam", this.permissions_history_cities);
      this.permissions_history_cities = this.sortArrayAlphabetically(
        this.permissions_history_cities
      );
      this.selected_city = this.permissions_history_cities[0];
    },
  },
};
</script>

<style></style>
