<template>
  <div>
    <vs-tabs>
    <vs-tab label="Manage Permissions">
      <vx-card actionable class="cardx" title="Manage Permissions">
      <vs-row type="flex" style="margin-top: 2%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
        <div class="flex justify-center items-center">
          <vs-button
            v-if="!getTeamForPermissions()"
            class="rounded-r-none p-3 md:px-8 md:py-3"
            @click="cmTeam('CM')"
            :color="colorx"
            :style="cmteamselectbuttoncolor"
            size="small"
            type="border"
            >CM</vs-button
          >
          <vs-button
            v-if="!getTeamForPermissions()"
            class="rounded-none p-3 md:px-8 md:py-3"
            @click="srTeam('SR')"
            :color="colorx"
            :style="srteamselectbuttoncolor"
            size="small"
            type="border"
            >SR</vs-button
          >
          <vs-button
            v-if="!getTeamForPermissions()"
            class="rounded-l-none p-3 md:px-8 md:py-3"
            @click="exedTeam('ExEd')"
            :color="colorx"
            :style="exedteamselectbuttoncolor"
            size="small"
            type="border"
            >ExEd</vs-button
          >
        </div>

        </vs-col>  
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <v-select
            v-if="!getTeamForPermissions()"
            id="courseselect"
            class="w-full"
            placeholder="City"
            v-model="city"
            :options="cities"
            label="text"
            style="height: 20px; z-index: 1000;bottom: 8px; position: relative;"
          ></v-select>
        </vs-col>
          <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          v-if="spoc_id === '132' || spoc_id === '39' || spoc_id === '77' || spoc_id === '432' || spoc_id === '937' || spoc_id === '1704'"
        >
         Queue Permission for PAN India : <vs-input-number  label="Hours:" color="dark" v-model="queue_time"/> &nbsp;
           <vs-button
                    color="#975846"
                     size="small"
                     type="border"
                    @click="grantQueuePermission"
                  >Grant</vs-button>
        </vs-col>
      </vs-row>
      <vs-row type="flex" style="margin-top: 2%">
        <vs-table :data="CitySpocs" style="width: 100%">
          <template slot="thead">
            <vs-th>City</vs-th>
            <vs-th>SPOC Name</vs-th>
            <vs-th>Give Permission</vs-th>
            <vs-th>Time Left</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody ref="tbody">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <div>{{ tr.city }}</div>
                </vs-td>
                <vs-td>
                  <div>{{ tr.full_name }}</div>
                </vs-td>
                <vs-td>
                  <div>
                    <vs-button
                      color="#975846"
                      type="border"
                      :disabled="buttonDisableStatus(tr)"
                      @click="givePermission(tr)"
                      >{{ getButtonText(tr) }}</vs-button
                    >

                    <vs-button
                      v-if="buttonDisableStatus(tr) && !getTeamForPermissions()"
                      style="margin-left: 2%"
                      color="danger"
                      type="border"
                      @click="revokePermission(tr)"
                      >Revoke</vs-button
                    >
                  </div>
                </vs-td>
                <vs-td :data="data[indextr].time_left_formatted">
                  <div>
                    <timer :time="tr.relax_queue_time"></timer>
                  </div>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vs-row>
    
      </vx-card>
    </vs-tab>
    <vs-tab label="Permissions History">
    <permissions-history :permissions_history_cities="cities"></permissions-history>
    </vs-tab>
    </vs-tabs>
    <vs-popup class="holamundo" title="Grant Permission" :active.sync="open">
      <vs-row style="margin-bottom: 3%">
        <vs-col v-if="spoc_id === '132' || spoc_id === '39' || spoc_id === '77' || spoc_id === '432' || spoc_id === '937'" vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <b> <vs-input-number  label="Hours:" color="dark" v-model="hours"/></b>
        </vs-col>
        <vs-col v-if="spoc_id !== '132' && spoc_id !== '39' && spoc_id !== '77' && spoc_id !== '432' && spoc_id !== '937' && getTeamForPermissions()" vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <b> <vs-input-number disabled="true" label="Hours:" color="dark" v-model="hours"/></b>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button color="#975846" type="border" @click="postToServer()"
            >Grant</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import axios from "axios";
import constants from "../../constants.json";
// import EventBus from "../components/eventbus";
import vSelect from "vue-select";
import timer from "./PermissionCountDownTimer.vue";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import PermissionsHistory from "../components/pagesComponents/PermissionsHistory.vue";
export default {
  data() {
    return {
      spoc_id: "",
      open: false,
      colorx: "",
      currentTablePage: 1,
      tablelinks: 1,
      city: "",
      cities: [],
      spoc_names: [],
      iiml_spocs:[],
      spocs: [],
      CitySpocs: [],
      SpocName:"",
      filter: "pending",
      tabColor: "warning",
      selectedSpoc: null,
      hours: 1,
      queue_time: 1,
      now: "",
      roles: ["IIML-PM", "IIML-HR", "IITR-BF", "IIML-BA"],
      // city:"",
      selectedTeam: "CM",
      cmteamselectbuttoncolor: [
        {
          background: "rgb(199, 42, 117)",
          color: "#ffffff",
          border: "1px solid rgb(199, 42, 117)",
        },
      ],
      srteamselectbuttoncolor: [
        {
          background: "#ffffff",
          color: "rgb(199, 42, 117)",
          border: "1px solid rgb(199, 42, 117)",
          borderLeftStyle: "none",
          borderRightStyle: "none"
        },
      ],
      exedteamselectbuttoncolor: [
        {
          background: "#ffffff",
          color: "rgb(199, 42, 117)",
          border: "1px solid rgb(199, 42, 117)",
        },
      ],
      audit_users:['kabir.chawla@mileseducation.com','sharon.j@mileseducation.com','athira.m@mileseducation.com','nidhin.ram@mileseducation.com','ghouse.ahmed@mileseducation.com','punith.rao@mileseducation.com','satyam.bhivgade@mileseducation.com','krishna.kanth@mileseducation.com','satram.chaitanya@mileseducation.com']
    };
  },
  components: {
    vSelect,
    timer,
    DateRangePicker,
    PermissionsHistory
  },
  
  watch: {
    // selected_city(value){ 
    //   this.selected_city = value;
    // },
    city(value) {
      if(value !== null){
      this.loadSpocs(value);
      } else {
        this.loadSpocs = [];
      }
    },
  },
  created() {
  },
  computed: {
  },
  mounted() {
    this.spoc_id = localStorage.getItem("spoc_id");
    this.cities = [];
    this.spocs = [];
    if(this.getTeamForPermissions()){
      this.getGmReportingSpocs("");
    }
    if(!this.getTeamForPermissions()){
      this.getSpocs(this.selectedTeam);
    }
    if(this.team == "CM") {
      this.getSpocs(this.selectedTeam);
    } else if(this.team == "Exed") {
      this.getExed();
    } 
  },
  methods: {
    getTeamForPermissions(){
      let logged_user = localStorage.getItem("email_id");
      if (this.audit_users.includes(logged_user)) {
        return false
      } else {
        return this.getTeam('GM')
      }
    },
    grantQueuePermission(){
       let url = `${constants.SERVER_API}relaxQueuePermissionForPanIndia`;
       let obj = {
         relax_queue_time : this.queue_time
       }
      axios
        .post(url,obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {   
          console.log("relaxQueuePermissionForPanIndia",response);
          if(response.data.status == 'success' ){
            this.$vs.notify({
							title: 'Successfully relaxed queue',
							color: "success",
						})
             if(this.getTeamForPermissions()){
                this.getGmReportingSpocs("");
              }
              if(!this.getTeamForPermissions()){
                this.getSpocs(this.selectedTeam);
              }
              if(this.team == "CM") {
                this.getSpocs(this.selectedTeam);
              } else if(this.team == "Exed") {
                this.getExed();
              } 
          }else {
               this.$vs.notify({
							title: 'Error : Please contact admin',
							color: "danger",
						})
          }      
        })
        .catch((error) => {
          this.handleError(error);
           this.$vs.notify({
							title:  `${error}`,
							color: "danger",
						})
        });
    },
    getGmReportingSpocs(team) {
      let url = `${constants.SERVER_API}getGmReportingSpocs?team=${team}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {   
          console.log("getGmReportingSpocs",response);
           this.spoc_names = response.data.spocs;
          this.spoc_names.forEach((spoc) => {
            spoc.time_left_formatted = "00:00:00";
            spoc.full_name_team = `${spoc.full_name} - ${spoc.city}`;

            // spoc.displaySpocTime = false;
          });
          this.extractSpocByCity(response.data.spocs, team);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    cmTeam(team) {
      this.cities = [];
      (this.spoc_names = []), (this.CitySpocs = []), (this.city = "");
      console.log("team", team);
      this.selectedTeam = team;
      this.getSpocs();
      this.exedteamselectbuttoncolor = [
        {
          background: "#ffffff",
          color: "rgb(199, 42, 117)",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
      this.cmteamselectbuttoncolor = [
        {
          background: "rgb(199, 42, 117)",
          color: "#ffffff",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
      this.srteamselectbuttoncolor = [
        {
          background: "#ffffff",
          color: "rgb(199, 42, 117)",
          border: "1px solid rgb(199, 42, 117)",
          borderLeftStyle: "none",
          borderRightStyle: "none"
        },
      ];
    },
    srTeam(team) {
      this.cities = [];
      (this.spoc_names = []), (this.CitySpocs = []), (this.city = "");
      console.log("team", team);
      this.selectedTeam = team;
      this.getSRSpocs();
      this.exedteamselectbuttoncolor = [
        {
          background: "#ffffff",
          color: "rgb(199, 42, 117)",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
      this.srteamselectbuttoncolor = [
        {
          background: "rgb(199, 42, 117)",
          color: "#ffffff",
          border: "1px solid rgb(199, 42, 117)",
          borderLeftStyle: "none",
          borderRightStyle: "none"
        },
      ];
      this.cmteamselectbuttoncolor = [
        {
          background: "#ffffff",
          color: "rgb(199, 42, 117)",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
    },
    exedTeam(team) {
      this.cmteamselectbuttoncolor = [
        {
          background: "#ffffff",
          color: "rgb(199, 42, 117)",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
      this.srteamselectbuttoncolor = [
        {
          background: "#ffffff",
          color: "rgb(199, 42, 117)",
          border: "1px solid rgb(199, 42, 117)",
          borderLeftStyle: "none",
          borderRightStyle: "none"
        },
      ];
      this.exedteamselectbuttoncolor = [
        {
          background: "rgb(199, 42, 117)",
          color: "#ffffff",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
      this.selectedTeam = team;
      this.cities = [];
      (this.spoc_names = []), (this.CitySpocs = []), (this.city = "");
      this.getExed(team);
      console.log("team", team);
    },
    givePermission(spoc) {
      this.hours = 1;
      this.selectedSpoc = spoc;
      // console.log(this.selectedSpoc);
      this.open = true;
    },
    revokePermission(tr) {
      console.log(tr);
      let obj = {
        spoc_id: tr.id,
      };
      axios
        .post(`${constants.SERVER_API}revokePermission`, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          // console.log(response);
          this.handleNotification(response);
          if (response.data.status === "success") {
            this.spoc_names.forEach((spoc) => {
              if (spoc.id === tr.id) {
                spoc.relax_queue_time = null;
              }
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    postToServer() {
      let hours = parseInt(this.hours);
      // let time = moment(moment().add(hours, "hours")).unix();
      let obj = {
        spoc_id: this.selectedSpoc.id,
        relax_queue_time: hours,
      };
      axios
        .post(`${constants.SERVER_API}relaxQueue`, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          // console.log(response);
          this.handleNotification(response);
          if (response.data.status === "success") {
            this.spoc_names.forEach((spoc) => {
              if (spoc.id === this.selectedSpoc.id) {
                spoc.relax_queue_time = response.data.relax_queue_time;
              }
            });
            this.open = false;
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    loadSpocs(cityName) {
      this.CitySpocs = [];
      this.spoc_names.forEach((spoc) => {
        if (spoc.city === cityName) {
          this.CitySpocs.push(spoc);
        } else if (spoc.sub_team === cityName){
          this.CitySpocs.push(spoc);
        }
      });
    },
    calculateTime() {
      // setInterval(() => {
      // this.calculateTimeLeft();
      // }, 1000);
    },
    calculateTimeLeft() {
      this.CitySpocs.forEach((spoc) => {
        // spoc.displaySpocTime = false;
        if (spoc.relax_queue_time !== null) {
          let current_time = moment();
          let queue_expiry_time = moment.unix(spoc.relax_queue_time);
          let time_difference = this.secondsToHms(
            queue_expiry_time.diff(current_time, "seconds")
          );
          if (
            queue_expiry_time.diff(current_time, "seconds") === 0 ||
            queue_expiry_time.diff(current_time, "seconds") < 0
          ) {
            spoc.time_left_formatted = "00:00:00";
            // console.log("00:00:00");
          } else {
            console.log(time_difference);
            // this.$nextTick(() => {
            spoc.time_left_formatted = time_difference;
            // spoc.displaySpocTime = true;
            // });
          }
        }
      });
    },
    buttonDisableStatus(tr) {
      let status = null;
      if (tr.relax_queue_time === null) {
        status = false;
      } else {
        let current_time = moment();
        let queue_expiry_time = moment.unix(tr.relax_queue_time);
        if (
          queue_expiry_time.diff(current_time, "seconds") === 0 ||
          queue_expiry_time.diff(current_time, "seconds") < 0
        ) {
          status = false;
        } else {
          status = true;
        }
      }
      return status;
    },
    getButtonColor(tr) {
      let status = null;
      if (tr.relax_queue_time === null) {
        status = "#975846";
      } else {
        let current_time = moment();
        let queue_expiry_time = moment.unix(tr.relax_queue_time);
        if (
          queue_expiry_time.diff(current_time, "seconds") === 0 ||
          queue_expiry_time.diff(current_time, "seconds") < 0
        ) {
          status = "#975846";
        } else {
          status = "success";
        }
      }
      return status;
    },
    getButtonText(tr) {
      let status = null;
      if (tr.relax_queue_time === null) {
        status = "Grant Permission";
      } else {
        let current_time = moment();
        let queue_expiry_time = moment.unix(tr.relax_queue_time);
        if (
          queue_expiry_time.diff(current_time, "seconds") === 0 ||
          queue_expiry_time.diff(current_time, "seconds") < 0
        ) {
          status = "Grant Permission";
        } else {
          status = "Permission Granted";
        }
      }
      return status;
    },
    secondsToHms(secs) {
      var minutes = Math.floor(secs / 60);
      secs = secs % 60;
      var hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(secs)}`;
    },
    pad(num) {
      return ("0" + num).slice(-2);
    },
    getSpocs(team) {
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.spoc_names = response.data.spocs;
          this.spoc_names.forEach((spoc) => {
            spoc.time_left_formatted = "00:00:00";
            spoc.full_name_team = `${spoc.full_name} - ${spoc.city}`
            
            // spoc.displaySpocTime = false;
          });
          this.extractSpocByCity(response.data.spocs,team);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSRSpocs(team) {
      let url = `${constants.SERVER_API}getSRSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.spoc_names = response.data.spocs;
          this.spoc_names.forEach((spoc) => {
            spoc.time_left_formatted = "00:00:00";
            spoc.full_name_team = `${spoc.full_name} - ${spoc.city}`
            
            // spoc.displaySpocTime = false;
          });
          this.extractSpocByCity(response.data.spocs,team);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getExed(team) {
      let url = `${constants.SERVER_API}getExed`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("hey",response);
          this.spoc_names = response.data.spocs;
          this.spoc_names.forEach((spoc) => {
            spoc.time_left_formatted = "00:00:00";
            spoc.full_name_team = `${spoc.full_name} - ${spoc.sub_team}`
          });
          this.loggedin = response.data.logged_in_user_id;
          this.SpocName = response.data.spocs;
          this.extractSpocBySubTeam(response.data.spocs,team);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    extractSpocByCity(response) {
      var unique = response
        .map((name) => {
          return {
            count: 1,
            name: name.city,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      let city_options = ["Ahmedabad","Bangalore","Chennai","Delhi","Ernakulam","Hyderabad","Kolkata","Mumbai","Pune",]
      this.cities = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          if(city_options.includes(key)){
            this.cities.push(key);
          }
        }
      }
      this.cities = this.sortArrayAlphabetically(this.cities);
      // this.permissions_history_cities = this.sortArrayAlphabetically(this.cities);
      // if (this.city == "") {
      this.city = this.cities[0];
      // this.selected_city = this.cities[0];
      // }
      // this.calculateTime();
    },
    extractSpocBySubTeam(response) {
      var unique = response
        .map((name) => {
          return {
            count: 1,
            name: name.sub_team,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
        
      this.cities = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          if(key != "IIML-FA" && key != "IIML-SF" && key != "IIML-FT" && key != "null") {
            this.cities.push(key);
          }
        }
      }
      console.log("gsdyuas", this.cities);
      this.cities = this.sortArrayAlphabetically(this.cities);
      this.city = this.roles[0];
    }
  },
};
</script>

<style></style>
